.home-featured-services {
  max-width: 1025px;
  width: 90%;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;
  @media (max-width: 640px) {
    padding: 60px 0;
  }
  h2 {
    font-family: $heading-font-family;
    font-size: 36px;
    color: #000000;
    line-height: 52px;
    text-transform: uppercase;
    margin: 10px 0;
    @media (max-width: 640px) {
      font-size: 28px;
      line-height: 32px;
    }
    h3 {
      color: #939393;
      font-size: 14px;
      letter-spacing: 2.33px;
      font-family: $heading-font-family;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: 20px;
    }
  }
  .btn-find-more {
    background: #eb427f;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-family: $heading-font-family;
    font-size: 15.44px;
    font-weight: 800;
    line-height: 1.5em;
    text-transform: uppercase;
    padding: 17px 25px;
    @media (max-width: 400px) {
      span {
        display: none;
      }
    }
    &:hover {
      color: #fff;
      text-decoration: none;
      background: #ce386e;
    }
  }
}

.home-featured-services-inner {
  margin: 85px auto;
  @media (max-width: 640px) {
    margin: 40px auto;
  }
  .home-featured-service-item {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 900px) {
      width: 100%;
      display: block;
      margin-bottom: 25px;
    }
    &:nth-child(2) {
      margin: 0 3.5%;
      @media (max-width: 900px) {
        margin: 0 0 55px;
      }
    }
    img {
      max-height: 98px;
      margin-bottom: 10px;
      @media (max-width: 640px) {
        max-height: 49px;
      }
    }
    h4 {
      font-size: 22px;
      color: #606060;
      line-height: 32px;
      font-family: $heading-font-family;
      text-transform: uppercase;
      margin: 25px 0;
      @media (max-width: 640px) {
        margin: 10px 0;
      }
    }
    p {
      opacity: 0.69;
      font-size: 16px;
      color: #9ca0a2;
      line-height: 24px;
      @media (max-width: 640px) {
        margin: 0 0 50px 0;
      }
    }
  }
}
