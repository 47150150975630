/* Expertise */
.expertise-simple {
  overflow: hidden;
  background: url("/assets/img/expertise-cms-simplicity-bg.png") no-repeat;
  height: 411px;
  width: 777px;
  margin: auto;
  position: relative;
}
.expertise-simple #expertise-simple-title {
  position: absolute;
  left: 24px;
  top: 117px;
  height: 33px;
  width: 332px;
  padding: 5px;
  border: 1px #cfcfcf solid;
  overflow: hidden;
}
.expertise-simple #expertise-simple-txt {
  position: absolute;
  left: 24px;
  top: 173px;
  height: 239px;
  padding: 5px;
  width: 332px;
  border: 1px #cfcfcf solid;
  overflow: hidden;
}
.expertise-simple #expertise-simple-title-output {
  width: 372px;
  padding: 15px;
  font-size: 22px;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  background: #f2f7fa;
}
.expertise-simple #expertise-simple-txt-output {
  width: 372px;
  font-size: 14px;
  line-height: 1.5em;
  padding: 25px 15px;
  height: 252px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
}
.expertise-simple #expertise-simple-wrap {
  position: absolute;
  right: 1px;
  top: 100px;
}
.expertise-simple #expertise-simple-wrap p {
  margin-bottom: 1.5em;
}
.expertise-simple-img {
  text-align: center;
  display: none;
}
.expertise-simple-img img {
  max-width: 90%;
}

.expertise-bottom-img {
  text-align: center;
  width: 100%;
}
.expertise-bottom-img img {
  max-width: 90%;
  margin: 0 auto;
}

#expertise-media-management {
  width: 880px;
  height: 230px;
  margin: 0 auto 100px;
}
#expertise-media-management #inner {
  position: relative;
  width: 100%;
  height: 230px;
}
#expertise-media-management .thumb {
  width: 110px;
  height: 85px;
  float: left;
  position: relative;
  top: 70px;
}
#expertise-media-management .thumb:nth-child(2) {
}
#expertise-media-management #dropzone {
  float: left;
  width: 540px;
  margin: 0 60px;
  background: #f2f7fa;
  border: 3px dashed #d9dcdd;
  height: 218px;
  text-align: center;
}
#expertise-media-management #dropzone.triggered {
  background: #f8f2fa;
}
#expertise-media-management #dropzone div {
  display: table-cell;
  vertical-align: middle;
  width: 540px;
  height: 218px;
}
#expertise-media-management #dropzone span#label {
  font-weight: 500;
  font-size: 19px;
  color: #a6a9aa;
}
#expertise-media-management #dropzone span#label strong {
  font-weight: 700;
}
#uploading {
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 48px;
  display: none;
}

.expertise-media-img {
  display: none;
  text-align: center;
}

@media (max-width: 900px) {
  .expertise-simple {
    display: none;
  }
  .expertise-simple-img {
    display: block;
  }

  #expertise-media-management {
    display: none;
  }
  .expertise-media-img {
    display: block;
  }
}

#expertise-vouchers {
  width: 840px;
  margin: 0 auto;
  height: 315px;
  overflow: hidden;
}
#expertise-vouchers-img {
  width: 374px;
  float: left;
  border: 1px #e0e0e0 solid;
  border-radius: 10px;
  padding: 10px;
}
#expertise-vouchers-img img {
  max-width: 100%;
  border: 1px #d5d5d5 solid;
}
#expertise-vouchers-txt {
  padding: 25px 0 25px 35px;
  width: 465px;
  float: left;
}
#expertise-vouchers-txt .title {
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
  line-height: 1.35em;
  display: block;
}
#expertise-vouchers-txt #price {
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 38px;
  line-height: 1.35em;
  color: #eb4983;
  margin: 0 0 30px;
  display: block;
}
#expertise-vouchers-txt .voucher-caption {
  display: block;
  margin-bottom: 5px;
  color: rgba(96, 96, 96, 0.7);
  font-size: 16px;
}
#expertise-vouchers-txt #voucher_txt {
  background: #f8fbfd;
  border: 1px solid #d9dee1;
  border-radius: 0;
  display: block;
  height: 42px;
  line-height: 42px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}
#expertise-vouchers-txt #voucher_btn {
  display: block;
  color: #fff;
  border: 0;
  padding: 15px;
  background: #eb4983;
  width: 100%;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
}

#expertise-vouchers-txt #voucher_btn:active {
  position: relative;
  top: 1px;
}

#voucher-static-img {
  display: none;
}

@media (max-width: 900px) {
  #expertise-vouchers {
    display: none;
  }
  #voucher-static-img {
    display: block;
  }
}

.expertise-content {
  padding-top: 20px;
  border-top: 1px solid #d6d6d6;
  &.first {
    padding-top: 0;
    border: 0;
  }
}

.expertise-photography {
  background-image: -o-linear-gradient(-89deg, #ffffff 60%, #f2f7fa 100%);
  background-image: -moz-linear-gradient(-89deg, #ffffff 60%, #f2f7fa 100%);
  background-image: -ms-linear-gradient(-89deg, #ffffff 60%, #f2f7fa 100%);
  background-image: linear-gradient(-179deg, #ffffff 60%, #f2f7fa 100%);
}
