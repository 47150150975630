.photograph-content {
  margin-bottom: 100px;
  .inner-wrap {
    padding-left: 0;
    padding-right: 0;
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
  }
  img {
    float: left;
    &:nth-child(1) {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 560px;
      width: calc(50.8771929825% - 20px);
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 560px;
      width: calc(50.8771929825% - 20px);
      margin-bottom: 20px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(3) {
      width: 560px;
      width: calc(50.8771929825% - 20px);
      margin-bottom: 20px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(4) {
      width: 365px;
      width: 32.0175438596%;
      clear: both;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(5) {
      width: 365px;
      width: calc(35.5263157895% - 40px);
      margin-left: 22.5px;
      margin-right: 22.5px;
      @media (max-width: 1260px) {
        margin-left: 21.5px;
        margin-right: 21.5px;
      }
      @media (max-width: 800px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
      }
    }
    &:nth-child(6) {
      width: 365px;
      width: 32.0175438596%;
      @media (max-width: 800px) {
        width: 100%;
        margin-top: 20px;
      }
    }
    &:nth-child(7) {
      margin-top: 20px;
      width: 100%;
      float: none;
    }
    &:nth-child(8) {
      margin-top: 20px;
      width: 560px;
      width: calc(50.8771929825% - 20px);
      margin-right: 20px;
      @media (max-width: 800px) {
        width: 100%;
        margin-right: 0;
      }
    }
    &:nth-child(9) {
      width: 560px;
      width: calc(50.8771929825% - 20px);
      margin-top: 20px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(10) {
      width: 367px;
      width: 32.1929824561%;
      clear: both;
      margin-top: 20px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(11) {
      width: 365px;
      width: calc(35.5263157895% - 40px);
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      @media (max-width: 800px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &:nth-child(12) {
      width: 367px;
      width: 32.1929824561%;
      margin-top: 20px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(13) {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .product-img-wrap {
    width: 365px;
    width: 32.0175438596%;
    background: #f9f9f9;
    border: 1px solid #dddddd;
    display: inline-block;
    text-align: center;
    @media (max-width: 800px) {
      width: 100%;
    }
    img {
      width: auto;
      margin: 0;
      float: none;
      max-width: 80%;
    }
    &:nth-child(3n) {
      width: calc(35.5263157895% - 40px);
      margin-left: 22.5px;
      margin-right: 22.5px;
      @media (max-width: 1260px) {
        margin-left: 21.5px;
        margin-right: 21.5px;
      }
      @media (max-width: 800px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    &.mid {
    }
  }
}
