.portfolio-content {
  padding-top: 20px;
}

.portfolio-content .content-header p {
  max-width: 1060px;
  margin-bottom: 1.5em;
}

.portfolio-content .content-header h1 {
  margin-bottom: 40px;
}

.case-studies {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
  align-items: stretch;
  -webkit-align-items: stretch;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.case-studies > .cs {
  flex: 1 0 calc(33.3% - 20px);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(33.3% - 20px);
  -webkit-flex: 1 0 calc(33.3% - 20px);
  -ms-flex: 1 0 calc(33.3% - 20px);
  background: #ccc;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 20px;

  @media (max-width: 1480px) {
    flex: 1 0 calc(50% - 20px);
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 20px);
    -webkit-flex: 1 0 calc(50% - 20px);
    -ms-flex: 1 0 calc(50% - 20px);
  }

  @media (max-width: 992px) {
    flex: 1 0 calc(100% - 20px);
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 20px);
    -webkit-flex: 1 0 calc(100% - 20px);
    -ms-flex: 1 0 calc(100% - 20px);
  }
}

.case-studies > .cs h1 {
  font-size: 34px;
  line-height: 34px;
  color: #fff;
  padding: 50px 0 10px 0;

  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 26px;
    padding: 50px 0 5px 0;
  }
}

.case-studies > .cs p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  margin-bottom: 30px;
  font-weight: 100;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.case-studies > .cs a {
  display: block;
  padding: 0 20px;
}

.case-studies > .cs a:hover {
  text-decoration: none;
}
.case-studies > .cs a:focus {
  text-decoration: none;
}

.case-studies > .cs img {
  max-height: 200px;
  max-width: 80%;
}

/* Portfolio Overview */
ul.client-logos {
  max-width: 1150px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
}

ul.client-logos:after {
  content: "";
  display: block;
  clear: both;
  margin-bottom: 50px;

  @media (max-width: 992px) {
    margin-bottom: 80px;
  }
}

ul.client-logos li {
  width: 25%;
  border-top: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  display: table;
  text-align: center;
  float: left;
}

ul.client-logos li:nth-child(-n + 4) {
  border-top: none;
}

ul.client-logos li:nth-child(4n + 1) {
  border-left: none;
}

ul.client-logos li a {
  display: table-cell;
  box-sizing: border-box;
  height: 140px;
  vertical-align: middle;
}

ul.client-logos li a:hover img {
  opacity: 0.8;
}

ul.client-logos li img {
  width: 70%;
}

@media (max-width: 768px) {
  ul.client-logos li {
    width: 50%;
  }

  ul.client-logos li:nth-child(-n + 2) {
    border-top: none;
  }

  ul.client-logos li:nth-child(3),
  ul.client-logos li:nth-child(4) {
    border-top: 1px solid #ebebeb;
  }

  ul.client-logos li:nth-child(4n + 1) {
    border-left: none;
  }

  ul.client-logos li:nth-child(2n + 1) {
    border-left: none;
  }

  ul.client-logos:after {
    margin-bottom: 60px;
  }
}

@media (max-width: 680px) {
  ul.client-logos li a {
    height: 100px;
  }

  ul.client-logos li img {
    max-width: 60%;
  }

  .img-gallery li {
    padding: 5px;
  }

  .img-gallery li.quarter {
    width: 25%;
    padding: 10px;
  }
}

.btn-wrapper {
  margin: 0 0 100px;
  text-align: center;
}

.btn-view-all {
  background: $reflex-pink;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-family: $heading-font-family;
  font-size: 15.44px;
  font-weight: 800;
  line-height: 1.5em;
  padding: 17px 25px;
  text-transform: uppercase;
  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background: #ce386e;
    color: #fff;
    text-decoration: none;
  }
}

.portfolio-featured-img {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  display: block;
}

.portfolio-featured-img-full {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.portfolio-featured-img-with-space {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 140px;
  display: block;
}

.portfolio-featured-img-browser {
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  display: block;
}

.portfolio-oakmore-listings {
  width: 90%;
  max-width: 912px;
  margin: 0 auto;
  display: block;
}

.featured-portfolio-app {
  max-height: 580px;
  max-width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
  display: block;
}

.portfolio-alt-background {
}

#pretty-dolly-stock {
  max-width: 502px;
}

#oakmore-mapping {
  max-width: 721px;
}

.timebar-color {
  background: #000000;
}

.timebar-color .read-case-btn {
  color: #1a1919;
}

.timebar-alt-color {
  background: #353535;

  a {
    color: #f9ea00;
    border-bottom: 1px solid #f9ea00;

    &:hover {
      color: #f9ea00;
      border-bottom: 1px solid #f9ea00;
      opacity: 0.8;
    }
  }
}

.timebar-alt-color-2 {
  background: url("/assets/img/time-trees.png") bottom center repeat-x #000;

  a {
    color: #f9ea00;
    border-bottom: 1px solid #f9ea00;

    &:hover {
      color: #f9ea00;
      border-bottom: 1px solid #f9ea00;
      opacity: 0.8;
    }
  }
}

.pretty-dolly-color {
  background: #d44175;
}

.pretty-dolly-color .read-case-btn {
  color: #d44175;
}

.pretty-dolly-alt-color {
  background: #f6f6f6;
}

.eurbike-color {
  background: #173a63;
}

.eurbike-color .read-case-btn {
  color: #ed8a8b;
}

.eurbike-alt-color {
  background: #f2f7fa;
}

.oakmore-property-color,
.case-studies > .oakmore-property-color {
  background: #d0a78d;
}

.oakmore-property-alt-color {
  background: #f2ede8;
}

.my-lovely-app {
  background: #588d4c;
}

.my-lovely-app-alt {
  background: #403730;
}

.ireland-craft-beers {
  background: #197e83;
}

.ireland-craft-beers .read-case-btn {
  color: #197e83;
}

.titanic-suites-color {
  background: #7bb368;
}

.highseaspirits-color {
  background: #0b3a53;
  color: #fff;
}

.highseaspirits-color .read-case-btn {
  color: #0b3a53;
}

.highseaspirits-alt-color {
  background: #f2f7fa;
}

.beautifullife-color {
  background: #52376e;
}

.beautifullife-color .read-case-btn {
  color: #52376e;
}

.beautifullife-alt-color {
  background: #e5dded;
}

.blackline-color {
  background: #1d1d1d;
}

.blackline-color p a {
  color: rgba(255, 255, 255, 0.7);
  border-bottom-color: rgba(255, 255, 255, 0.7);
}

.blackline-color .read-case-btn {
  color: #1d1d1d;
}

.blackline-color-alt {
  background: #ebebeb;
}

.riddell-color {
  background: #a56696;
}

.riddell-color .read-case-btn {
  color: #52376e;
}

.riddell-color-alt-color {
  background: #e5dded;
}

.elite-color {
  background: #292929;
}

.elite-color .content-header h1 {
  color: #d8bd79;
}

.elite-color-stationary {
  padding-top: 0;
}

.elite-color-stationary .content-header {
  background: url("/assets/img/elite-stationary-bck.png") top right no-repeat;
  padding: 100px 0 10px 0;
  background-size: 100% 100%;
}

.dandk-color {
  background: #731e2e;
}

.turco-color {
  background: url("/assets/img/turco-bck1.png") top right no-repeat #f26631;
  background-size: contain;
}

.turco-color2 {
  background: url("/assets/img/turco-bck2.png") top left no-repeat #f26631;
  background-size: contain;
}

.bmi-color-light {
  background: #f2f7fa;
}

.bmi-color-dark {
  background: #2b78a0;
}

.epl-color {
  background: #282357;
}

/* Portfolio Detailed View */
.featured-portfolio-screenshot {
  max-width: 751px;
  width: 90%;
  margin: 0 auto;
  display: block;
}

.portfolio-content {
  iframe {
    margin: 0 auto;
  }

  &.white-text {
    h1 {
      color: white;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.portfolio-general-txt {
  text-align: center;

  p {
    text-align: center;
  }

  a {
  }
}

.case-study-header {
  background-position: center;
  background-size: cover;

  h1 {
    color: white;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }
}
.case-study-header .thumbnail-img {
  max-width: 751px;
  width: 90%;
  margin: 0 auto;
  display: block;
}

.case-study-header .thumbnail-img,
.case-study-header .mobile-app {
  padding-top: 95px;
}

.portfolio-video {
  max-width: 100%;
}

.btn-icon {
  display: inline-block;
  margin-left: 25px;
}

#dropdown_sel {
}

/* Beautiful Life */
.beautifullife-alt-color {
  #dropzone {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-size: cover;
    border: 3px #fff solid;
    overflow: hidden;
    top: 80px;
    left: calc(50% - 50px);
    z-index: 999999;

    .dz-default {
      width: 100px;
      height: 100px;
      background: url("../imgs/beautifullife-card-imgs/default.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      span {
        display: none;
      }
    }

    .dz-preview {
      position: absolute;
      top: 0;
      left: 0;

      .dz-image {
        img {
        }
      }

      .dz-details,
      .dz-progress,
      .dz-error-message,
      .dz-success-mark,
      .dz-error-mark,
      .dz-upload,
      .dz-success-mark {
        display: none;
      }
    }
  }
}

.card-background-wrap {
  position: relative;
  width: 370px;
  display: block;
  margin: 0 auto;
  height: 511px;
  margin: 45px auto;

  @media (max-width: 480px) {
    width: 330px;
  }

  .front {
  }

  .back {
    background: #f7f7f7;
    border: 1px solid #cecece;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }

  .hotspot {
    height: 39px;
    width: 39px;
    line-height: 39px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #000;
    position: absolute;
    z-index: 500;
    border-radius: 50%;
    cursor: pointer;

    &.hotspot-bg {
      top: 55px;
      left: 55px;
    }

    &.hotspot-prev {
      top: 65%;
      left: -10px;
      font-size: 18px;

      span {
        -ms-transform: rotate(180deg);
        /* IE 9 */
        -webkit-transform: rotate(180deg);
        /* Chrome, Safari, Opera */
        transform: rotate(180deg);
        display: block;
      }

      &:active {
        top: calc(65% + 1px);
      }

      &:hover {
        background: #eee;
      }
    }

    &.hotspot-nxt {
      top: 65%;
      right: -10px;
      font-size: 18px;

      &:active {
        top: calc(65% + 1px);
      }

      &:hover {
        background: #eee;
      }
    }
  }

  .card-inner {
    position: absolute;
    width: 100%;
    height: 511px;
    display: block;
    z-index: 150;

    ::-webkit-input-placeholder {
      color: white;
    }

    :-moz-placeholder {
      color: white;
    }

    ::-moz-placeholder {
      color: white;
    }

    :-ms-input-placeholder {
      color: white;
    }

    .card-profile-img {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      //  background:url('../imgs/beautifullife-profile-pic.jpg') no-repeat;
      background-size: cover;
      border: 3px #fff solid;
      overflow: hidden;
      top: 80px;
      left: calc(50% - 50px);
    }

    .card-title {
      position: absolute;
      left: 0;
      top: 200px;
      width: 100%;
      color: #fff;
      text-align: center;
      display: block;
      font-size: 24px;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
      background: none;
      border: 0;
      height: 28px;
      padding: 0;
      margin: 0;
    }

    .date {
      font-size: 17px;
      color: #fff;
      display: block;
      margin: 0;
      font-weight: 700;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
      position: absolute;
      left: 0;
      width: 100%;
      top: 233px;
    }

    #rotate-poem {
      width: 260px;
      position: absolute;
      left: calc(50% - 130px);
      top: 285px;
      color: #fff;
      line-height: 1.35em;
      background: rgba(#020001, 0.4);
      padding: 25px;

      .card-txt {
        display: none;
        opacity: 0;
        transition: all 0.3s ease;

        textarea {
          width: 100%;
          font-size: 15px;
          line-height: 1.35em;
          min-height: 90px;
          background: none;
          border: 0;
          color: inherit;

          @media (max-width: 480px) {
            min-height: 70px;
          }
        }

        &.poem-active {
          display: block;
          opacity: 1;
        }
      }
    }
  }
}

.card-border {
  position: absolute;
  z-index: 200;
  left: 0;
  top: 0;
  background: url("../imgs/beautifullife-card-border.png");
  width: 100%;
  height: 511px;
  pointer-events: none;

  @media (max-width: 480px) {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.card-background-img {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  background: url("../imgs/beautifullife-card-bg.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 511px;
  pointer-events: none;

  @media (max-width: 480px) {
    height: 455px;
  }
}

.theme-header {
  padding: 25px;
  background: #52376f;
  text-align: left;

  .pull-left {
    width: calc(100% - 75px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pull-right {
    width: 64px;

    button {
      background: #38c9ba;
      border-radius: 2px;
      border: 1px solid #2ca094;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
      color: #ffffff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0px;
      padding: 5px 15px;
      text-transform: uppercase;
      transition: all 0.3s ease;
      line-height: 1.35em;
      position: relative;

      &:active {
        top: 1px;
      }
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1em;
    color: #fff;
    margin-bottom: 5px;
  }

  span {
    display: inline-block;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 1em;
  }
}

.theme-body {
  padding: 25px;
  background: #f7f7f7;
  overflow-y: scroll;
  height: 555px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;

    li {
      width: 100px;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;

      .img-wrap {
        overflow: hidden;
        height: 100px;

        img {
          height: 100px;
          max-width: none;
        }
      }

      input {
        box-shadow: none;
        width: auto;
        display: inline-block;
        margin: 0 0 10px;
      }
    }
  }
}

.portfolio-content.first {
  text-align: center;
  margin: 0 auto;
  padding-top: 0;
  border: 0;
}

.img-gallery {
  margin: 0 auto 140px auto;
  max-width: 1140px;
  font-size: 0;
  padding: 0 10px;
}

.img-gallery img {
  width: 100%;
}

.img-gallery li {
  display: inline-block;
  padding: 10px;
}

.img-gallery li.full {
  width: 100%;
}

.img-gallery li.half {
  width: 50%;
}

.img-gallery li.third {
  width: 33.3333%;
}

.img-gallery li.quarter {
  width: 25%;
}

.img-gallery li.border img {
  border: 1px solid #e6e6e6;
}

.img-gallery li.quarter {
  width: 25%;
  padding: 10px;
}

.slashes {
  display: inline-block;
  color: #8f8f8f;
  margin: 0 5px 0 10px;

  &:last-child {
    display: none !important;
  }
}

p.services {
  margin-bottom: 1.5em;
}
