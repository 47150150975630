section.regular {
  position: relative;
}

.inner-wrap {
  max-width: 1160px;
  width: 90%;
  margin: auto;
  padding: 20px 25px;
}

.page-mid {
  .inner-wrap {
    padding: 50px 0;
  }
}

.page-inner-wrap {
  position: relative;
  background-color: white;
  z-index: 100;
  left: 0;
  transition: left 0.7s;

  &.opened {
    left: 100vw;
  }

  a {
    position: relative;
    &:active {
      top: 1px;
    }
  }
}
