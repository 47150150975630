.blog-listings-item {
  clear: both;
  margin: 80px auto;
  width: 90%;
  max-width: 744px;
  color: #8f8f8f;
  text-align: center;
  border-top: 1px solid #d6d6d6;
  padding-top: 100px;

  &:nth-child(1) {
    border-top: none;
    padding-top: 0px;
  }

  .btn {
    background: #eb427f;
    border-radius: 2px;
    display: inline-block;
    font-family: "brandon-grotesque", "Brandon Grotesque";
    font-size: 14px;
    font-weight: 800;
    line-height: 12px;
    padding: 18px 20px;
    text-transform: uppercase;
    color: #fff;

    &:hover {
      background: #ce386e;
      text-decoration: none;
    }
  }
}

.blog-listings-item-header {
  .thumb {
    overflow: hidden;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 0 auto 20px auto;
    border-radius: 100%;

    img {
      width: 80px;
    }
  }

  .fleft {
    h1 {
      color: #000;
      font-size: 38px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 42px;
      margin-bottom: 5px;

      a {
        color: #000;
      }
    }

    h3 {
      font-family: "adelle";
      font-size: 16px;
      color: #8f8f8f;
      font-weight: 300;
      text-align: center;
      text-transform: none;

      a {
        color: #eb427f;
        font-weight: 700;

        &:hover {
          color: #ce386e;
        }
      }
    }
  }
}

.blog-listings-item-summary {
  p {
    margin: 30px 0;
    font-size: 19px;
    color: #8f8f8f;
    line-height: 34px;
    font-weight: 300;
    text-align: center;
  }
}

.blog-details {
  max-width: 740px;
  width: 90%;
  margin: 95px auto 65px;
  color: #8f8f8f;
  font-family: "adelle";
  font-weight: 300;
  font-size: 19px;
  line-height: 34px;
  &.jobdetails{
    @media (max-width: 800px) {
      margin: 45px auto 65px;
    }
    @media (max-width: 600px) {
      margin: 0 auto 65px;
    }
  }

  p {
    margin-bottom: 1.5em;
  }

  img {
    margin-left: -5%;
    margin-right: -5%;
    width: 110%;
    margin-bottom: 1.5em;
  }

  h1 {
    font-size: 1.15em;
    margin: 10px 0;
    color: #000;
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.15em;
    margin: 10px 0;
    color: #000;
  }

  .jobtitle{
    margin-bottom: 0;
  }

  .subtitle{
    color: #ed4682;
    margin-top: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 1em;
    margin: 5px 0;
    color: #000;
  }

  ul,
  ol {
    margin: 0 0 1.5em;

    li {
      list-style: disc;
      padding: 0 5px;
      margin: 0 30px;

      a {
        color: #ed4682;
        border-bottom: 1px solid #ed4682;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }

  blockquote {
    font-size: 14px;
    line-height: 23px;
    font-style: italic;
  }

  .videowrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.hasAuthor {
  .pageTitle-inner {
    max-width: 880px;
    position: relative;

    h3 {
      font-family: "adelle";
      font-size: 16px;
      color: #8f8f8f;
      font-weight: 300;
      text-align: center;
      text-transform: none;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      a {
        color: #eb427f;
        font-weight: 700;

        &:hover {
          color: #ce386e;
        }
      }
    }
  }
}

.author {
  position: absolute;
  left: calc(50% - 40px);
  bottom: -100px;
}

.author-inner {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin: auto;
  overflow: hidden;

  img {
    width: 80px;
  }
}

.recent-posts {
  max-width: 740px;
  width: 90%;
  margin: 35px auto 105px;

  h2 {
    text-transform: uppercase;
    font-size: 24px;
    color: #000;
    font-family: "brandon-grotesque", "Brandon Grotesque";
    font-weight: 900;
    border-bottom: 1px #e0e0e0 solid;
    padding: 0 0 15px;
    margin: 0;
  }

  ul {
    margin: 55px auto;

    li {
      margin: 0 0 60px;

      @media (max-width: 768px) {}


      h3 {
        text-transform: uppercase;
        font-family: "brandon-grotesque", "Brandon Grotesque";
        font-weight: 900;
        font-size: 16px;
        margin: 0 0 5px;
        color: #000;
        line-height: 16px;

        a {
          color: #000;
        }
      }

      .author-details {
        h3 {
          color: #8f8f8f;
          font-family: "adelle";
          font-size: 14px;
          font-weight: 300;
          float: left;
          margin: 3px 0;
          text-transform: none;

          @media (max-width: 768px) {
            font-size: 12px;
          }

          a {
            font-weight: 700;
            color: #eb427f;
            font-size: 14px;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.author-small {
  clear: both;
  width: 28px;
  height: 28px;
  margin-right: 7px;
  float: left;

  img {
    border-radius: 100%;
    overflow: hidden;
    height: 28px;
    width: 100%;
  }
}

figure {
  margin: 50px 0 50px 0;
  position: relative;

  figcaption {
    font-size: 13px;
    line-height: 13px;
    font-style: italic;
    font-weight: 600;
    display: block;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 14px 0 10px 0;
    color: #fff;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}

@media (max-width: 1480px) {}

@media (max-width: 1200px) {}

@media (max-width: 1080px) {}

@media (max-width: 992px) {
  .blog-listings-item-summary p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {

  .blog-listings-item-summary p,
  .blog-details p,
  li,
  a {
    font-size: 16px;
    line-height: 28px;
  }

  .blog-listings-item-header .fleft h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .blog-listings-item-header .fleft h3 {
    font-size: 14px;
    margin: 10px 0 0 0;
  }

  .blog-listings-item {
    margin: 50px auto;
    padding-top: 60px;
  }

  .pageTitle {
    padding: 40px 0;
  }

  .pageTitle.hasAuthor {
    padding: 40px 0 60px 0;
  }

  .pageTitle-inner h1 {
    font-size: 38px;
  }

  .pageTitle-inner p {
    font-size: 14px;
  }

  figure {
    margin: 30px 0;
  }
}

@media (max-width: 680px) {}

#load-more-btn {
  background: #f2f7fa;
  border: 1px solid #cecccc;
  border-radius: 3px;
  font-family: "brandon-grotesque", "Brandon Grotesque";
  font-size: 13.79px;
  color: #606060;
  line-height: 19px;
  margin: 0 auto;
  display: block;
  text-align: center;
  cursor: pointer;
  width: 90%;
  max-width: 744px;
  text-transform: uppercase;
  font-weight: 900;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10%;

  &:active {
    position: relative;
    top: 1px;
  }

  &:hover {
    background: #e7eff3;
  }
}