#mobile-nav-items {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #243040;

  .mid {
    ul {
      box-sizing: border-box;
      padding: 200px 0;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;

      @media (max-width: 768px) {
        padding: 60px 0;
      }

      li {
        color: white;
        height: 8.5vh;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;

        @media (max-width: 768px) {
          //width: 380px;
          height: 12vh;
        }

        .page {
          text-transform: uppercase;
          color: #fff;
          font-size: 46px;
          line-height: 46px;
          font-family: "brandon-grotesque", "Brandon Grotesque";
          font-weight: 900;

          &:hover {
            color: #ed4682;
            text-decoration: none;
          }
        }

        .job {
          text-transform: uppercase;
          background-color: #ed4682;
          color: #fff;
          width: fit-content;
          font-size: 20px;
          line-height: 1;
          font-family: "brandon-grotesque", "Brandon Grotesque";
          font-weight: 900;
          margin: 0 auto;
          margin-top: 40px;
          padding: 20px 30px;
          border-radius: 4px;
          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }

        &:last-child {
          height: 22vh;

          @media (max-width: 768px) {
            margin-top: 20px;
          }
        }

        p {
          margin: 5px 0 0 0;
          color: #fff;

          @media (max-width: 768px) {
            line-height: 0;
          }

          a {
            color: #fff;
            border: none;

            &:hover {
              color: #ed4682;
              text-decoration: none;
            }
          }
        }

        .fa,
        .fas {
          font-size: 16px;
          color: #ed4682;
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-height: 850px) {
  .main-nav-inner ul {
    padding: 150px 0;
  }
}

@media (max-height: 800px) {
  .main-nav-inner ul {
    padding: 100px 0;
  }
}

@media (max-height: 750px) {
  .main-nav-inner ul {
    padding: 75px 0;
  }
}

@media (max-height: 700px) {
  .main-nav-inner ul {
    padding: 50px 0;
  }
}

@media (max-height: 600px) {
  .main-nav-inner ul {
    padding: 50px 0 70px 0;
  }

  .main-nav-inner ul li a {
    font-size: 30px;
    line-height: 30px;
  }

  .main-nav-inner ul li p,
  .main-nav-inner ul li .fa {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-nav-trigger {
    top: 40px;
  }

  @media (max-height: 460px) {
    .main-nav-inner ul {
      padding: 3% 0 2% 0;
    }

    .main-nav-inner ul li a {
      font-size: 30px;
      line-height: 30px;
    }

    .main-nav-inner ul li p,
    .main-nav-inner ul li .fa {
      font-size: 12px;
    }
  }

  @media (max-height: 400px) {
    .main-nav-inner ul {
      padding: 2% 0;
    }

    .main-nav-inner ul li {
      height: 20%;
    }

    .main-nav-inner ul li a {
      font-size: 24px;
      line-height: 24px;
    }

    .main-nav-inner ul li:last-child {
      display: none;
    }
  }
}
