a.btn-project-sm {
  //float: right;
  background: $reflex-pink;
  color: #fff;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 15px;
  border-radius: 2px;
  margin-top: 10px;
  margin-right: 38px;
  position: relative;
  top: -8px;
  &:active {
    top: -7px;
  }
  @media (max-width: 768px) {
    margin-top: 5px;
  }
}

.btn-start-project {
  display: inline-block;
  background: $reflex-pink;
  color: #fff;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  padding: 20px 40px;
  border-radius: 3px;
  margin: 50px 0;

  @media (max-width: 992px) {
    font-size: 16px;
    margin: 40px 0;
    padding: 15px 30px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px 24px;
  }
}

.btn-project-sm:hover,
.btn-start-project:hover {
  color: #fff;
  text-decoration: none;
  background: #ce386e;
}

.btn-start-project:active,
.btn-start-project:focus,
.btn-project-sm:active,
.btn-project-sm:focus {
  color: white;
  text-decoration: none;
}

.btn-grey {
  background: #525252;
}

.btn-grey:hover {
  background: #333;
}

.btn-portfolio,
a.btn-portfolio {
  border: 1px solid #ccc;
  background: #f8f8f8;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 0 2px;
}

a.btn-portfolio .fa,
.btn-inactive .fa {
  font-size: 20px;
  color: #8f8f8f;
  line-height: 40px;
}

.btn-inactive {
  opacity: 0.5;
}

.btn-inactive .fa {
  opacity: 0.3;
}

a.btn-portfolio:hover {
  background: #ececec;
}

.btn-download-app {
  display: inline-block;
  color: #fff;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16.55px;
  line-height: 20px;
  border-radius: 3px;
  margin: 50px 0;
  position: relative;

  @media (max-width: 992px) {
    font-size: 16px;
    margin: 40px 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

#download-btn-txt {
  display: inline-block;
  padding: 20px;
}

#dropdown_sel {
  border-left: 1px solid #636363;
  display: inline-block;
  padding: 10px 28px 10px 12px;
  font-size: 22px;
  position: relative;
  cursor: pointer;
  width: 85px;
}

#download-btn-txt {
  color: #fff;
  text-decoration: none;
}

#dropdown_icons_inner {
  display: inline-block;
  text-align: center;
}

#dropdown_icons_inner i {
  display: block;
  padding: 10px 0;
}

#dropdown_icons_inner i:nth-child(2) {
  display: none;
}

.fa-wrapper {
  position: absolute;
  top: 19px;
  height: 25px;
  font-size: 16px;
  margin-left: 10px;
  display: inline-block;
}

.fa-wrapper i {
  display: block;
  position: absolute;
}

.fa-wrapper i:nth-child(2) {
  top: 0;
}

.fa-wrapper i:nth-child(1) {
  bottom: 0;
}
