html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $body-font-family;
  line-height: 1;
  &.fixed {
    position: fixed;
    background-color: #243040;
  }
}

main {
  width: 100%;

  &:active {
    position: relative;
    top: 1px;
  }
}

.page-wrap {
  overflow: hidden;
  width: 100vw;
}

p {
  a {
    color: #ed4682;
    border-bottom: 1px solid #ed4682;
    &:hover {
      color: #ce386e;
      text-decoration: none;
      border-bottom: 1px solid #ce386e;
    }
    &:active,
    &:focus {
      color: #ed4682;
      text-decoration: none;
    }
  }
}

.hero {
  text-align: center;
  padding-top: 140px;
  background: #d44175;

  h1 {
    color: #fff;
    font-size: 64px;
    line-height: 64px;
    max-width: 90%;
    margin: 0 auto;

    @media (max-width: 1200px) {
      font-size: 60px;
    }

    @media (max-width: 992px) {
      font-size: 50px;
      line-height: 50px;
    }

    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  h2 {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
    max-width: 90%;
    margin: 10px auto 40px auto;

    @media (max-width: 1200px) {
      font-size: 20px;
    }

    @media (max-width: 992px) {
      font-size: 18px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      margin: 20px auto 30px auto;
      line-height: 20px;
    }
  }

  .pretty-dolly-hero {
    max-height: 477px;
    max-width: 90%;
    position: relative;
    background: #d44175;
  }

  .hero-img {
    max-height: 477px;
    max-width: 90%;
    position: relative;
  }
}

.project-cta {
  background: #f2f7fa;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  text-align: center;
  box-sizing: border-box;
  padding: 100px 5%;

  h1 {
    color: #939393;
    font-size: 14px;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 46px;
    font-family: $heading-font-family;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 46px;
    margin: 20px 0 40px 0;

    @media (max-width: 992px) {
      font-size: 38px;
      margin: 5px auto 20px auto;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      margin: 20px auto 30px auto;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  p {
    font-size: 21px;
    line-height: 35px;
    max-width: 770px;
    margin: 0 auto;
    color: rgba(96, 96, 96, 0.7);
    font-weight: 100;

    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 32px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }

    em {
      font-weight: 700;
      color: rgba(96, 96, 96, 0.7);
    }

    strong,
    a[href^="tel"] {
      color: #606060;
    }
  }
}

.case-study-home-container {
  font-size: 0;

  .case-study-home {
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 80px 5% 0 5%;
    font-size: 20px;
    text-align: center;
    vertical-align: top;

    @media (max-width: 900px) {
      width: 100%;
    }

    h1 {
      color: #fff;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 100;
    }

    a.read-case-btn {
      display: inline-block;
      background: #fff;
      font-family: $heading-font-family;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 14px;
      line-height: 12px;
      padding: 18px 20px;
      border-radius: 2px;
      margin: 20px 0 60px 0;

      &:hover {
        text-decoration: none;
        opacity: 0.95;
      }
    }

    img {
      max-height: 247px;
      max-width: 90%;
      display: block;
      margin: 0 auto;
    }
  }

  .study-timebar {
    background: #000000;

    a {
      color: #000000;
    }
  }

  .study-beautifullife {
    background: #52376e;

    a {
      color: #52376e;
    }
  }
}

/*
 Generic Page Titles
*/
.pageTitle {
  padding: 60px 0;
  margin-bottom: 50px;
  background: #f2f7fa;

  h1 {
    margin-bottom: 0;
  }
}

.pageTitle-inner {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1150px;
  padding: 0 20px;

  h1 {
    display: block;
    width: 100%;
    font-weight: 900;
    color: #000;
    font-size: 46px;
    line-height: 42px;
    margin-bottom: 10px;
  }

  span {
    font-weight: 300;
    color: #606060;
  }

  p {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: #8f8f8f;
  }

  a {
    padding: 0;
    border: 0;
    color: $reflex-pink;
    font-weight: 700;
    text-decoration: none;

    &[href^="tel"] {
      display: inline-block;
    }
  }
}

.content-header {
  padding: 100px 0 60px 0;
  text-align: center;

  @media (max-width: 768px) {
    padding: 60px 0 20px 0;
  }

  h1 {
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 20px;
    padding: 0 20px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  p {
    font-size: 20px;
    line-height: 35px;
    max-width: 880px;
    margin: 0 auto;
    color: rgba(96, 96, 96, 0.7);
    font-weight: 100;
    padding: 0 20px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      padding: 0 20px;
    }
  }

  .services {
    font-size: 15px;
  }
}

#loader {
  text-align: center;
  display: none;
  padding: 50px 0;

  div {
    color: #606060;
    font-size: 26px;
    line-height: 1.5em;
  }
}

.subnav {
  background: #f2f7fa;
  border-bottom: 1px solid #d6d6d6;

  @media (max-width: 768px) {
    display: none;
  }
}

.subnav-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  justify-content: center;
  -webkit-justify-content: center;
  max-width: 1150px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px;

  @media (max-width: 874px) {
    padding: 0 10px;
  }
}

.subnav-inner a {
  border-left: 1px solid #ccc;
  text-align: center;
  padding: 25px 22px;

  @media (max-width: 900px) {
    padding: 15px 10px;
  }
}

.subnav-inner a:hover {
  background: #e7eff3;
  text-decoration: none;
}

.subnav-inner a.active {
  background: #e7eff3;
}

.subnav-inner a:first-child {
  border-left: 1px solid #f2f7fa;
}

.subnav-inner a:last-child {
  border-right: 1px solid #f2f7fa;
}

.subnav-inner a:first-child.active,
.subnav-inner a:first-child:hover {
  border-left: 1px solid #ccc;
}

.subnav-inner a:last-child.active,
.subnav-inner a:last-child:hover {
  border-right: 1px solid #ccc;
}

.subnav-inner a h1 {
  font-size: 13px;
  line-height: 13px;
  color: #000;

  @media (max-width: 1080px) {
    font-size: 10px;
  }
}

.subnav-inner a img {
  max-height: 50px;
  height: 50px;
  max-width: 80%;
  margin-bottom: 15px;

  @media (max-width: 1080px) {
    max-height: 25px;
  }
}

.subnav-mobile {
  display: none;

  a {
    display: block;
    text-align: center;
    font-size: 13px;
    line-height: 50px;
    font-family: $heading-font-family;
    text-transform: uppercase;
    font-weight: 700;
    color: #000000;
    text-decoration: none;

    h1 {
      background: #f2f7fa;
      border-bottom: 1px solid #d6d6d6;
      display: none;

      &:hover {
        background: #e7eff3;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
}

a.subnav-mobile-button {
  background: #525252;
  line-height: 50px;
  font-weight: 900;
  color: #fff;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  &:hover {
    background: #333;
  }

  .fa {
    margin-left: 5px;
  }

  .open .fa {
    transform: rotate(-180deg);
  }
}

.pageTitle {
  &.margin-bottom-0 {
    margin-bottom: 0;
  }
}

.not-found-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.block-on-mobile {
  @media (max-width: 600px) {
    height: 0;
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
