.footer {
  background: #fff;
  .footer-inner {
    max-width: 1130px;
    margin: 0 auto;
    width: 90%;
    box-sizing: border-box;
    padding: 35px 0 30px;
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
      text-align: center;
    }
    .col {
      &:nth-child(1) {
        flex-grow: 1;
        p {
          font-size: 14px;
          line-height: 1.6;
          margin-bottom: 5px;
          a {
            color: #ed4682;
            border-bottom: 1px solid #ed4682;
          }
        }
        a {
          color: #333;
        }
        span.rating-desc {
          font-size: 12px;
          line-height: 1;
        }
      }
      &:nth-child(2) {
        @media (max-width: 600px) {
          padding-top: 15px;
        }
        a {
          .fab {
            font-size: 24px;
            line-height: 24px;
            color: #606060;
            margin-left: 10px;
          }
          &:hover {
            .fab {
              color: #333;
            }
          }
        }
      }
    }
  }
}
