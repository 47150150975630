/* ICONS */
@font-face {
  font-family: "reflex-icon-font";
  src: url("../fonts/reflex-icon-font.eot");
  src: url("../fonts/reflex-icon-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/reflex-icon-font.woff") format("woff"),
    url("../fonts/reflex-icon-font.ttf") format("truetype"),
    url("../fonts/reflex-icon-font.svg#reflex-icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "reflex-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "reflex-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bespoke:before {
  content: "a";
}
.icon-websites:before {
  content: "b";
}
.icon-view-all:before {
  content: "c";
}
.icon-responsive:before {
  content: "d";
}
.icon-graphic:before {
  content: "e";
}
.icon-ecommerce:before {
  content: "f";
}
.icon-branding:before {
  content: "g";
}
.icon-cms:before {
  content: "h";
}
.icon-email:before {
  content: "i";
}
.icon-search:before {
  content: "j";
}
.icon-new:before {
  content: "k";
}
.icon-refresh:before {
  content: "l";
}

/* */
