.standard-page-template {
  .pageTitle-inner {
    h1 {
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    h2 {
      font-size: 18px;
      font-family: $body-font-family;
      font-weight: 300;
      color: #919191;
      line-height: 28px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      span {
        display: block;
        color: $reflex-pink;
        font-weight: 700;
        font-style: italic;

        @media (max-width: 740px) {
          display: inline;
        }
      }
    }
  }

  .content-header {
    padding-top: 30px;
    text-align: center;

    p {
      max-width: 940px;
      margin: 0 auto;
      color: rgba(96, 96, 96, 0.7);
    }

    h3 {
      font-family: $heading-font-family;
      font-size: 46px;

      @media (max-width: 990px) {
        font-size: 38px;
        line-height: 1.5em;
      }

      @media (max-width: 765px) {
        font-size: 30px;
        line-height: 1.5em;
      }

      @media (max-width: 500px) {
        font-size: 24px;
        line-height: 1.5em;
      }

      font-weight: 900;
      color: #000000;
      line-height: 1.5em;
      text-transform: uppercase;
    }
  }

  #video {
    max-width: 1160px;
    margin: 0 auto 80px auto;
    width: 90%;

    @media (max-width: 765px) {
      margin: 0 auto 20px auto;
    }

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .sep {
    border-top: 1px #ececec solid;
    width: 100%;
    max-width: 1140px;
    margin: 85px auto 35px;
  }

  .testimonials-wrap {
    max-width: 1140px;
    margin: 30px auto;
    vertical-align: top;

    .quotation-mark {
      color: #f2f2f2;
      font-size: 230px;

      @media (max-width: 1100px) {
        font-size: 200px;
        width: 74px;
      }

      @media (max-width: 900px) {
        font-size: 180px;
        width: 64px;
      }

      @media (max-width: 750px) {
        font-size: 120px;
        width: 54px;
      }

      @media (max-width: 500px) {
        font-size: 70px;
        width: 44px;
      }

      @media (max-width: 380px) {
        font-size: 50px;
        width: 34px;
      }

      font-weight: 700;
      width: 94px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      line-height: 0.4em;
      position: relative;
    }

    .quotation-left {
      top: 60px;
    }

    .quotation-right {
      vertical-align: bottom;
      top: 100px;
    }
  }

  .testimonial-item {
    height: 100%;

    blockquote {
      width: calc(100% - 200px);

      @media (max-width: 1100px) {
        width: calc(100% - 175px);
      }

      @media (max-width: 900px) {
        width: calc(100% - 150px);
      }

      @media (max-width: 750px) {
        width: calc(100% - 130px);
      }

      @media (max-width: 500px) {
        width: calc(100% - 100px);
      }

      @media (max-width: 380px) {
        width: calc(100% - 70px);
      }

      display: inline-block;
      margin: 35px 0 0;
    }

    blockquote,
    blockquote p {
      font-family: $body-font-family;
      font-size: 20px;
      color: #919191;
      line-height: 39px;
      font-style: italic;
      font-weight: 300;
      text-align: left;
      overflow: hidden;
      padding: 0;

      @media (max-width: 650px) {
        font-size: 18px;
        line-height: 35px;
      }

      @media (max-width: 500px) {
        font-size: 16px;
        line-height: 31px;
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    cite {
      text-transform: uppercase;
      font-style: normal;
      text-align: left;
      margin: 35px 100px;
      display: block;

      @media (max-width: 480px) {
        text-align: center;
      }

      img {
        float: left;
        margin-right: 17px;
        height: 44px;
        border: 0;

        @media (max-width: 480px) {
          float: none;
          margin: 0 0 10px;
        }
      }

      .title-wrap {
        float: left;

        @media (max-width: 480px) {
          float: none;
        }

        h4 {
          font-family: $heading-font-family;
          font-size: 18px;
          font-weight: 900;
          margin: 5px 0;

          a {
            color: #333;
          }
        }

        h5 {
          font-family: $heading-font-family;
          font-size: 13px;
          font-weight: 700;
          color: #919191;

          a {
            color: #919191;
          }
        }
      }
    }
  }

  .center {
    text-align: center;
  }
}

/* pager */
.cycle-pager {
  text-align: center;
  width: 100%;
  z-index: 500;
  position: absolute;
  top: 10px;
  overflow: hidden;
}

.cycle-pager span {
  font-family: arial;
  font-size: 50px;
  width: 16px;
  height: 16px;
  display: inline-block;
  color: #ddd;
  cursor: pointer;
}

.cycle-pager span.cycle-pager-active {
  color: #d69746;
}

.cycle-pager>* {
  cursor: pointer;
}

.meet-the-team {
  max-width: 1160px;
  margin: 0 auto;
  width: 90%;
  text-align: center;

  h2 {
    font-size: 46px;
    font-family: "brandon-grotesque", "Brandon Grotesque";
    text-transform: uppercase;
    font-weight: 900;
    line-height: 46px;
    margin: 0;
  }

  p {
    margin: 35px auto 70px;
    font-size: 20px;
    line-height: 35px;
    max-width: 880px;
    color: rgba(96, 96, 96, 0.7);
    font-weight: 100;
    padding: 0 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      width: calc(33.333333% - 20px);
      display: inline-block;
      text-align: left;
      vertical-align: top;

      &:nth-child(2) {
        margin-left: 30px;
        margin-right: 30px;
      }

      @media (max-width: 900px) {
        width: 100%;

        &:nth-child(2) {
          margin-left: 0;
          margin-top: 30px;
          margin-bottom: 30px;
          margin-right: 0;
        }
      }

      .img-wrap {
        display: block;
        margin-bottom: 30px;

        img {
          width: 100%;
        }
      }

      h3 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: "brandon-grotesque", "Brandon Grotesque";
        text-transform: uppercase;
        color: #000000;
      }

      h4 {
        font-size: 13px;
        color: #eb427f;
        letter-spacing: 0;
        text-transform: uppercase;
        margin: 10px 0 25px;
        font-family: "brandon-grotesque", "Brandon Grotesque";
        text-transform: uppercase;
      }

      p {
        opacity: 0.7;
        font-size: 14px;
        color: #606060;
        line-height: 24px;
        margin: 0;
        padding: 0;
      }

      a {
        display: inline-block;
      }
    }
  }
}

.what-we-do {
  @extend .meet-the-team;
  padding: 0 0 105px;

  h2 {
    padding-top: 50px;
  }

  p {
    opacity: 0.7;
    font-size: 19px;
    color: #606060;
    line-height: 34px;
    max-width: 980px;
  }

  .row {
    margin: 0;
    display: flex;

    .col {
      width: calc(50% - 10px);
      overflow: hidden;

      img {
        width: 100%;
      }

      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-left: 10px;

        img:nth-child(1) {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.about-team-header {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.about-team-desc {
  padding-bottom: 100px;
  text-align: left;
  max-width: 800px;
  margin: 60px auto 0;

  p {
    font-size: 20px;
    line-height: 35px;
    max-width: 880px;
    margin: 0 auto;
    color: rgba(96, 96, 96, 0.7);
    font-weight: 100;
    padding: 0 20px;
    margin-top: 1.5em;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      padding: 0 20px;
    }
  }
}

.socialMedia-channels {
  display: block;
  width: 100%;
  margin-top: 10px;

  a {
    color: #eb427f;
    display: inline-block;
    margin: 0 5px;

    i {
      font-size: 24px;
    }
  }
}

.header-wwd {
  h1 {
    margin: 40px 0;

    @media (max-width: 968px) {
      margin: 0 0 40px 0;
    }
  }

  p {
    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 30px;
      line-height: 24px;
    }
  }
}