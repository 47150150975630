#listLocations {
  //border: 1px #ddd solid;
  border-top: 1px solid #dedede;
  border-bottom: 0;
  margin-top: 100px;

  .locationInner {
    font-size: 14px;
    color: #606060;
    line-height: 26px;
    // width: 50%;
    // float: left;
    // &:nth-child(1) {
    //   border-right: 1px #e6e6e6 solid;
    // }
  }

  .inner {
    margin: 35px auto;
    max-width: 350px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .map-marker-wrap {
      width: 70px;
      text-align: center;

      i {
        font-size: 61px;
        color: #e94580;
      }
    }

    .inner-txt {
      width: 245px;
      color: #cbcfd1;
      font-size: 14px;
      line-height: 1.5em;

      p:first-child {
        margin-bottom: 11px;
      }

      a {
        color: #cbcfd1;
        border: 0;
        font-weight: 600;
        padding-left: 5px;
      }
    }
  }

  .active {
    color: #606060;

    .inner {
      .map-marker-wrap i {
        color: #e94580;
      }

      .inner-txt {
        color: #606060;

        a {
          color: #606060;
        }
      }
    }
  }
}

.contact-content {
  max-width: 640px;
  margin: 0 auto 100px;
  text-align: center;
  font-weight: 300;

  .content-header {
    padding: 100px 0 0;
  }

  p {
    margin-bottom: 1.5em;
    font-size: 20px;
    line-height: 35px;
    color: rgba(96, 96, 96, 0.7);
    font-weight: 100;
    padding: 0 20px;
  }

  a {
    font-size: 26px;
    color: #ed4682;
  }
}

#enquiry {
  max-width: 680px;
  margin: 50px auto;

  fieldset {
    padding: 0;
    border: 0;
    margin: 0;
  }

  label {
    color: #606060;
    display: flex;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 5px;

    .required {
      color: #a01515;
      padding-left: 5px;
    }
  }

  input,
  textarea {
    -webkit-appearance: none;
    background: #f8fbfd;
    border-radius: 0;
    border: 1px solid #d9dee1;
    font-family: "adelle";
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 30px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 0;
    &.required,
    &.required_border {
      border: 1px solid #8b0000;
    }
  }

  button {
    display: block;
    width: 100%;
    -webkit-appearance: none;
    background: #525252;
    border-radius: 3px;
    border: 0;
    color: #ffffff;
    font-family: $heading-font-family;
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 1.5em;
    padding: 20px 40px;
    text-transform: uppercase;
  }
}

@media (max-width: 992px) {
  .contact-content p {
    font-size: 18px;
    line-height: 32px;
    padding: 0 20px;
  }

  .contact-content p a {
    font-size: 20px;
    display: inline-block;
    margin-bottom: 10px;
  }

  .contact-content .content-header {
    padding: 60px 0 0 0;
  }

  .contact-content {
    margin: 0 auto 60px;
  }
}

@media (max-width: 768px) {
  .contact-content,
  #enquiry {
    padding: 0px 25px;

    button {
      font-size: 14px;
      padding: 12px 24px;
    }
  }

  #listLocations .inner .map-marker-wrap {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  #listLocations .inner .map-marker-wrap {
    color: #e94580;
  }

  #listLocations .inner .inner-txt {
    float: none;
    width: 100%;
    text-align: center;
  }

  .contact-content p {
    font-size: 16px;
    line-height: 28px;
    padding: 0 20px;
  }
}

@media (max-width: 540px) {
  #listLocations .inner {
    margin: 15px auto;
  }

  #listLocations .inner .map-marker-wrap {
    margin-bottom: 5px;
  }

  #listLocations .inner .map-marker-wrap i {
    font-size: 38px;
  }

  #listLocations .locationInner {
    width: 100%;
    margin: 0 auto;
  }
}
