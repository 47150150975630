.location-header {
  background-color: #173a63;
  h2 {
    line-height: 1.35em;
    margin-top: 15px;
    max-width: 820px;
    font-size: 22px;
  }
  img {
    width: 90%;
    max-width: 1146px;
  }
}

.location-featured-services {
  max-width: 840px;
}

.location-featured-services .home-featured-services-inner {
  margin: 85px auto 20px auto;
  max-width: 800px;
}

.location-featured-services
  .home-featured-services-inner
  .home-featured-service-item
  h4 {
  font-size: 20px;
}

.location-description {
  margin: 40px 0 80px 0;
}

.location-description p {
  display: inline;
  margin-right: 3px;
  font-size: 19px;
  line-height: 32px;
  color: rgba(96, 96, 96, 0.7);
  font-weight: 100;
}

.content-header h1.location-h1,
.about-team-desc h1.location-h1 {
  font-size: 36px;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .location-header h2 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .location-header h2 {
    font-size: 18px;
  }

  .location-featured-services .home-featured-services-inner {
    display: none;
  }

  .location-description {
    margin: 40px 0;
  }

  .location-description p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .location-header h2 {
    font-size: 16px;
    margin: 20px auto 30px auto;
    line-height: 20px;
  }
}

@media (max-width: 768px) {
  .content-header h1.location-h1,
  .about-team-desc h1.location-h1 {
    font-size: 28px;
    line-height: 32px;
  }
}
