header {
  background-color: #fff;
  font-family: $title-font-family;
  text-transform: uppercase;
  position: relative;
  z-index: 100;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.32);

  .inner-wrap {
    display: flex;
    align-items: center;
    padding: 20px 25px;
    width: 100%;

    @media (max-width: 568px) {
      width: 100%;
    }

    a.logo {
      img {
        height: 65px;
      }
    }

    .col {
      &:nth-child(1) {
        flex-grow: 1;
      }
    }
  }
}

.burger-btn-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  pointer-events: none;

  .inner-wrap {
    position: relative;

    .hamburger {
      position: absolute;
      top: 0;
      right: 10px;
      pointer-events: all;
      transition: all 0.2s ease;
      border-radius: 100%;
      padding: 13px 3px 17px 14px;

      &.white {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background-color: white;
        }
      }

      &.is-active,
      &.scrolled {
        background-color: #eb427f;
        padding: 18px 3px 18px 14px;

        &:hover {
          opacity: 0.9;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background-color: white;
        }
      }
    }
  }
}

.section-,
.section-home,
[class^="section-web-design-"] {
  header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: none;
    box-shadow: none;
  }
}
