.hidden {
  display: none;
}
fieldset,
a img {
  border: 0 none;
}

button {
  border: 0;
}

.project-form {
  max-width: 1080px;
  padding: 0 5%;
  margin: 0 auto;
  .fleft {
    float: left;
    width: 49%;
  }
  .fright {
    float: right;
    width: 49%;
  }
  form {
    margin: 0 auto 60px;
  }
  fieldset {
    padding-bottom: 60px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 60px;
  }
  legend {
    margin-top: 60px;
    font-family: "brandon-grotesque", "Brandon Grotesque";
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
  }
  input,
  textarea {
    -webkit-appearance: none;
    background: #f8fbfd;
    border-radius: 0;
    border: 1px solid #d9dee1;
    font-family: "adelle";
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 30px;
    padding: 10px 15px;
    width: 100%;
  }
  textarea {
    min-height: 160px;
  }
  label {
    padding: 20px 0;
    margin-bottom: 0;
    display: block;
    color: #606060;
    font-family: "adelle";
    font-weight: 300;
  }
  .checkbox-wrap input {
    width: auto;
  }
  .checkbox-wrap-item {
    background: #f8fbfd;
    float: left;
    width: 32%;
    margin-right: 2%;
    border: 1px solid #d9dee1;
    font-size: 16px;
    color: #b5b5b5;
    margin-bottom: 20px;
    padding: 11px 10px 11px 40px;
    cursor: pointer;
    position: relative;
    line-height: 29px;
    &:nth-child(3n + 0) {
      margin-right: 0%;
    }
    &:hover {
      color: #333;
    }
    input {
      display: none;
    }
    .icon {
      font-size: 18px;
      margin: 0;
      position: absolute;
      left: 12px;
      top: 14.5px;
    }
  }
  .selected {
    border: 1px solid #f44081;
    font-weight: bold;
    color: #f44081;
    padding: 11px 10px 11px 40px;
    &:hover {
      color: #f44081;
    }
    span {
      color: #f44081;
      font-weight: bold;
    }
  }
}

.checkbox-wrap-item.selected {
  border: 1px solid #f44081;
  font-weight: bold;
  color: #f44081;
  padding: 11px 10px 11px 40px;
  &:hover {
    color: #f44081;
  }
  span {
    color: #f44081;
    font-weight: bold;
  }
}

.project-form .selected:hover {
  color: #f44081;
}

#budget_slider_sel,
#timeline_slider_sel {
  width: 80%;
  margin-bottom: 10px;
}

#timeline_slider_value,
#budget_slider_wrap {
  font-size: 0.9em;
  padding: 0 10px;
}
#budget_slider_wrap div {
  display: inline-block;
}

.project-form .sliders legend {
  margin-top: 0;
}
#attach_file {
  font-size: 12px;
  padding: 1.6%;
  margin: 20px 0 40px 0;
  line-height: 20px;
}
.project-form .about-you label {
  padding: 20px 0 0 0;
}
.noUi-handle,
.noUi-active,
.noUi-target {
  box-shadow: none !important;
}
#timeline_slider_value,
#budget_slider_wrap {
  padding: 10px 0 0 0;
  font-size: 18px;
  font-weight: 700;
}

.required {
  color: #a01515;
  padding-left: 5px;
}

@media only screen and (max-width: 780px) {
  .project-form .fleft {
    width: 100%;
    float: none;
  }
  .project-form .fright {
    width: 100%;
    float: none;
  }
  .project-form .sliders .fleft {
    margin-bottom: 75px;
  }
}

@media only screen and (max-width: 650px) {
  .project-form .checkbox-wrap-item {
    width: 45%;
  }
  .project-form .checkbox-wrap-item:nth-child(3n) {
    margin-right: 2%;
  }
}

@media only screen and (max-width: 540px) {
  .project-form .checkbox-wrap-item {
    width: 100%;
    clear: both;
    float: none;
  }
}

#contact_btn {
  width: 100%;
}

.project-form input.required_border,
.project-form textarea.required_border {
  border: 1px solid #a01515;
}

.fa-thumbs-up:before {
  font-size: 120px;
  margin-bottom: 30px;
}
