h3 {
  color: #939393;
  font-size: 14px;
  font-family: "brandon-grotesque", "Brandon Grotesque";
  font-weight: 900;
  text-transform: uppercase;
}

.meet-the-team {
  ul {
    li {
      h3 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: "brandon-grotesque", "Brandon Grotesque";
        text-transform: uppercase;
        color: #000000;
      }
    }
  }
}

.home-featured-services h3 {
  color: #939393;
  font-size: 14px;
  letter-spacing: 2.33px;
  font-family: "brandon-grotesque", "Brandon Grotesque";
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 20px;
}

h4 {
  font-family: $title-font-family;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  color: #222d3a;
}

h1 {
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 900;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}